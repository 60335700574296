import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><a parentName="h2" {...{
        "href": "https://obama-hypercube.web.app/"
      }}>{`Obama Hypercube`}</a></h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/obama-hypercube"
      }}>{`Github`}</a>{`
`}<a parentName="p" {...{
        "href": "https://codepen.io/Mamboleoo/pen/MWyNZYr"
      }}>{`credit`}</a>{` for much of the code`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      